import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { PureComponent, useEffect, useState } from 'react';



import ReportService from "../../services/report.service";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";



  
 
const Outedate = () => {
  const [rows, setrows] = useState({});
  const [item, setitem] = useState('');
  const [loading, setloading] = useState(false);
  useEffect(  ()  => {

 
   console.log(rows)
    ReportService.GetOutedate()
    .then((data) => data)
    .then((data) => setrows(data))
   
    .catch(function (err) {
      if (!err?.response) {
        
    } else if (err.response?.status === 400) {
    
    } else if (err.response?.status === 401 ||err.response?.status === 422) {
      console.clear();
      navigate('/');
      window.location.reload();
    }
    });
    // setloading(true)

  },[])
 
  
  const navigate = useNavigate();



    const container = React.useRef(null);
    const pdfExportComponent = React.useRef(null);
  return (
    <div style={{backgroundColor:'#ffffff'}}>
 <br></br>
   
        <div className="border rounded p-2 flex" >
       
          <div ref={container}  className="W100" >
            <h1 className="text-center" > عدد حالات الوفاه  </h1>
            <hr className="k-hr" />
          
    <table id="customers">
    <tr>
  
    

  



  <th style={{color:'#ffff',flex:1}}  className="text-center">العدد</th>
  <th style={{color:'#ffff',flex:1}}  className="text-center"> الأسم</th>
  </tr>
  { rows[0] != undefined &&  (rows).map((result,index)  =>   
  <tr className="text-center" >
  { rows[0] != undefined && Object.values(rows[index].outStaus).map((result,index)  =>     
  
              <td  className="text-center" style={{flex:1}}>{parseInt(result) ? parseInt(result) : 0}</td> 
        
    
           
       )}
        
     
        <td  className="text-center">{rows[index]?.oncology}</td> 
        </tr> 

) }
</table>
  
      
          </div>
       
      </div>
    </div>
  )
}

export default Outedate