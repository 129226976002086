import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Typography from "@mui/material/Typography";



import Cookies from "universal-cookie";
import { Box, Button, Radio } from "@mui/material";
import api from "../../api/axios";
import $, { map } from "jquery";
import Swal from "sweetalert2";
import DataService from "../../services/data.service";
import DrawerAppBar from "../AppBar";

const cookies = new Cookies();

const New = () => {
  const [page, SetPage] = useState(1);
  const navigate = useNavigate();
  const auth = useAuth();
  const [state, setState] = useState({});
  const [isloading, setisloading] = useState(true);
  const [isloading2, setisloading2] = useState(true);
  const [isloading3, setisloading3] = useState(true);
  const [nationality, setnationality] = useState([]);
  const [city, setcity] = useState([]);
  const [bloadtype, setbloadtype] = useState([]);
  const [medicine, setmedicine] = useState([]);
  const [oncology, setoncology] = useState([]);
  const [disease, setdisease] = useState([]);
  const [Check, setCheck] = useState([]);
  const [isMale, setisMale] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [formInputData, setformInputData] = useState({
    uuid: null,
    level: null,
    date: null,
    Name: null,
    code:null
  });
  const [formInputData2, setformInputData2] = useState({
    uuid: null,
    quantity: 1,
    medicine: null,
  });
  const [formInputData3, setformInputData3] = useState({
    uuid: null,
    diseases: null,
  });

  //valadion
  const handleChange = (event) => {
    const value = event.target.value;
    let a = [];
    let uuidvalue = null;
    if (event.target.name === "bloadtypeName") {
      bloadtype.map((bloadtype, index) => a.push(bloadtype.name));

      if (!a.includes(value.toUpperCase())) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#bloadtypelist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
      });
    }
    if (event.target.name === "nationalityName") {
      nationality.map((nationality, index) => a.push(nationality.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#nationalitylist [value="' + value + '"]').data("value");
        console.log(uuidvalue);
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
        libya: value,
      });
      console.log(state);
    }

    if (event.target.name === "status") {
      if (value === "") {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
        isout: true,
      });
    }
    if (event.target.name === "name") {
      if (value.length <= 5) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "mothername") {
      setCheck({ ...Check, [event.target.name]: true });

      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "phone_number") {
      if (value.length != 10) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "city") {
      city.map((city, index) => a.push(city.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
        uuidvalue = $('#citylist [value="' + value + '"]').data("value");
      }
      setState({
        ...state,
        [event.target.name]: uuidvalue,
      });
    }

    if (event.target.name === "NID") {
      console.log(state);
      if (value.length != 12 && state.libya === "ليبيا") {
        setCheck({ ...Check, [event.target.name]: true });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "medicine") {
      medicine.map((medicine, index) => a.push(medicine.name));

      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }

    if (event.target.name === "oncology") {
      oncology.map((oncology, index) => a.push(oncology.name));
      console.log(value);
      if (!a.includes(value)) {
        setCheck({ ...Check, [event.target.name]: false });
      } else {
        setCheck({ ...Check, [event.target.name]: true });
      }
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (event.target.name === "birthday") {
      setCheck({ ...Check, [event.target.name]: true });
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
    if (
      event.target.name != "city" &&
      event.target.name != "bloadtypeName" &&
      event.target.name != "nationalityName"
    ) {
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
  };
  const handleChangeTable1 = (evnt) => {
    setisloading(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    

    if (evnt.target.name === "level") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.oncology != undefined
      ) {
        setisloading(false);
      }
    }
    if (evnt.target.name === "date") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData(newInput);
      if (
        formInputData.uuid != undefined &&
        formInputData.level != undefined &&
        formInputData.oncology != undefined
      ) {
        setisloading(false);
      }
    }
    if (evnt.target.name === "oncology") {
      uuidvalue = $('#oncologylist [value="' + value + '"]').data("value");
    
let code 
      oncology.map((oncology, index) => (
       
      value==oncology.name?  code = oncology.code :''
         ));

      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
        code :code
      });

      if (uuidvalue != undefined) {
        setformInputData(newInput);
        console.log(formInputData)
        setCheck({ ...Check, [evnt.target.name]: true });
      
        if (
          formInputData.uuid != undefined &&
          formInputData.level != undefined &&
          formInputData.oncology != undefined
        ) {
          setisloading(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    console.log('testststst');
    console.log(formInputData);
  };
  const handleChangeTable2 = (evnt) => {
    setisloading2(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    console.log(value);

    if (evnt.target.name === "medicine") {
      uuidvalue = $('#medicinelist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData2(newInput);
        setCheck({ ...Check, [evnt.target.name]: true });
        if (
          formInputData2.uuid != undefined &&
          formInputData2.quantity != undefined &&
          formInputData2.medicine != undefined
        ) {
          setisloading2(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }
    if (evnt.target.name === "quantity") {
      if (evnt.target.value <= 0) {
        evnt.target.value = 1;
      }
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
      });
      setformInputData2(newInput);
      if (
        formInputData2.uuid != undefined &&
        formInputData2.quantity != undefined &&
        formInputData2.medicine != undefined
      ) {
        setisloading2(false);
      }
    }
    console.log(formInputData2);
  };
  const handleChangeTable3 = (evnt) => {
    setisloading3(true);
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    console.log(value);

    if (evnt.target.name === "diseases") {
      uuidvalue = $('#diseaselist [value="' + value + '"]').data("value");
      console.log(uuidvalue);

      var newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });

      if (uuidvalue != undefined) {
        setformInputData3(newInput);
       
        setCheck({ ...Check, [evnt.target.name]: true });
        if (formInputData3.uuid != undefined) {
          setisloading3(false);
        }
      } else {
        setCheck({ ...Check, [evnt.target.name]: false });
      }
    }

    setformInputData3(newInput);
    if (formInputData3.uuid != undefined) {
      console.log(formInputData3);
    } else {
      setisloading3(false);
    }
  };

  //Submint
  const handleSubmit = (evnt) => {
    setisloading(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData];
      setTableData(newData);
      const emptyInput = {
        uuid: undefined,
        level: undefined,
        date: undefined,
        Name: undefined,
      };
      setformInputData(emptyInput);
      document.getElementById("oncology").value = "";
      document.getElementById("date").value = "";
      document.getElementById("level").value = "";
    }
  };
  const handleSubmit2 = (evnt) => {
    console.log("farag");
    console.log(formInputData2);
    setisloading2(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData2).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData2];
      setTableData2(newData);
      const emptyInput = {
        medicine: undefined,
        quantity: undefined,
        uuid: undefined,
      };
      setformInputData2(emptyInput);
      document.getElementById("medicine").value = "";
      document.getElementById("quantity").value = 0;
    }
  };
  const handleSubmit3 = (evnt) => {
    console.log("farag");
    console.log(formInputData3);
    setisloading3(true);
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData3).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData3];
      setTableData3(newData);
      const emptyInput = {
        name: undefined,
        uuid: undefined,
      };
      setformInputData3(emptyInput);
      document.getElementById("diseases").value = "";
    }
  };
  function onSubmit() {
  
    const tokens = cookies.get("accessToken");
    console.log(state);
    console.log(isMale === true);
    // api
    //   .post(
    //     `/patient/init`,
    //     {
    //       status: state.status ? state.status : null,
    //       name: state.name ? state.name : null,
    //       mothername: state.mothername ? state.mothername :null,
    //       passport: state.passport ? state.passport : null,
    //       NID: state.NID ? state.NID : null,
    //       birthday: state.birthday ? state.birthday :null,
    //       indate: state.indate ? state.indate : null,
    //       outdate: state.outdate ? state.outdate : null,
    //       phone_number: state.phone_number ? state.phone_number : null,
    //       address: state.address ? state.address : null,
    //       ismale: isMale === true,
    //       isout: state.isout ? state.isout : false,
    //       city: state.city ? state.city : null,
    //       bloadtype: state.bloadtypeName ? state.bloadtypeName : null,
    //       nationality: state.nationalityName ? state.nationalityName : null,
    //       oncologys: tableData ? tableData : [{ uuid: null }],
    //       medicines: tableData2 ? tableData2 : [{ uuid: null }],
    //       diseases: tableData3 ? tableData3 : [{ uuid: null }],

    //       circles: [
    //         {
    //           date: "",
    //         },
    //       ],
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${tokens}`,
    //       },
    //     }
    //   )
    //   .catch(function (error) {
    //     console.log(error);
    //     if (error) {
    //       console.log(error.response);
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: error.response.data.error + "!",
    //       });
    //       return 0;
    //     }
    //   });
    // Swal.fire({
    //   icon: "success",
    //   title: "success...",
    //   text: "تم اضافه المريض",
    // }).then(() => window.location.reload())
 
  }

  //Delete
  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.value) {
        console.log(tableData);

        const employeesCopy = tableData.filter(
          (employee) => employee.uuid !== id
        );

        setTableData(employeesCopy);
        const employeesCopy2 = tableData2.filter(
          (employee) => employee.uuid !== id
        );

        setTableData2(employeesCopy2);
        const employeesCopy3 = tableData3.filter(
          (employee) => employee.uuid !== id
        );
        setTableData3(employeesCopy3);
      }
    });
  };
function Msg(params) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: params+ "!",
  });
  return 0;
}
  //Service Get Data
  try {
    useEffect(() => {
      DataService.GetCity()
        .then((data) => data)
        .then((data) => setcity(data)).catch(function (err) {
          if (!err?.response) {
            Msg('No Server Response');
        } else if (err.response?.status === 400) {
           Msg('error');
        } else if (err.response?.status === 401 ||err.response?.status === 422) {
          console.clear();
          navigate('/');
          window.location.reload();
        }
        });
      DataService.Getnationalitys()
        .then((data) => data)
        .then((data) => setnationality(data));
      DataService.Getbloadtypes()
        .then((data) => data)
        .then((data) => setbloadtype(data));
      DataService.Getdiseases()
        .then((data) => data)
        .then((data) => setdisease(data));
      DataService.Getmedicines()
        .then((data) => data)
        .then((data) => setmedicine(data));
      DataService.Getoncologys()
        .then((data) => data)
        .then((data) => setoncology(data))
    }, []);
   
  } catch (err) {
   
  }
  const navItems = [];
  return (
    <>
      <DrawerAppBar navItems={navItems}/>
    <div className="App">
     
      <div className="Home">
        {page === 1 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات الشخصيه</Typography>
            <div className="flex">
              <div className="0">
                <label className="label">الاسم رباعي</label>
                <br></br>
                <input
                  className="input"
                  value={state.name ? state.name : ""}
                  required
                  onChange={handleChange}
                  name="name"
                ></input>
                <h6 className="R">
                  {Check?.name === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
              <div className="0">
                <label className="label">اسم الأم</label>
                <br></br>
                <input
                  className="input"
                  value={state.mothername ? state.mothername : ""}
                  required
                  onChange={handleChange}
                  name="mothername"
                ></input>
                <h6 className="R">
                  {Check?.mothername === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الجنسية</label>
                <br></br>
                <div className="Box">
                  <input
                    className="input"
                    required
                    list="nationalitylist"
                    id="nationalityName"
                    // value={state.nationalityName ? state.nationalityName : ""}
                    name="nationalityName"
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    onChange={handleChange}
                  />
                  <h6 className="R">
                    {Check?.nationalityName === false
                      ? "يرجي التأكد من الجنسية  "
                      : ""}
                  </h6>
                  <datalist id="nationalitylist">
                    {nationality.map((nationality, index) => (
                      <option
                        key={nationality.uuid}
                        value={nationality.name}
                        data-value={nationality.uuid}
                      >
                        {" "}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="0">
                <label className="label">رقم الجواز</label>
                <br></br>
                <input
                  className="input"
                  required
                  value={state.passport ? state.passport : ""}
                  onChange={handleChange}
                  name="passport"
                ></input>
                <h6 className="R">
                  {Check?.passport === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="0">
                <label className="label">الرقم الوطني </label>
                <br></br>
                <input
                  className="input"
                  disabled={state.libya === "ليبيا" ? false : true}
                  value={state.NID ? state.NID : ""}
                  required
                  onChange={handleChange}
                  name="NID"
                ></input>
                <h6 className="R">
                  {Check?.NID === false ? "يرجي التأكد من عدد حروف " : ""}
                </h6>
              </div>

              <div className="0">
                <label className="label">تاريخ الميلاد </label>
                <br></br>
                <input
                  className="input1"
                  type="date"
                  required
                  value={state.birthday ? state.birthday : ""}
                  name="birthday"
                  onChange={handleChange}
                ></input>
              </div>
            </div>

            <div className="flex">
              <div className="b1">
                <label className="label">فصيلة الدم</label>
                <br></br>
                <div className="Box">
                  <input
                    className="input"
                    required
                    list="bloadtypelist"
                    id="bloadtypeName"
                    // value={state.bloadtypeName ? state.bloadtypeName : ""}
                    name="bloadtypeName"
                    style={{
                      margin: "1%",

                      textAlign: "end",
                      paddingRight: "2%",
                      height: "35px",
                    }}
                    onChange={handleChange}
                  />
                  <h6 className="R">
                    {Check?.bloadtypeName === false
                      ? "يرجي التأكد من فصيلة الدم "
                      : ""}
                  </h6>
                  <datalist id="bloadtypelist">
                    {bloadtype.map((bloadtype, index) => (
                      <option
                        key={bloadtype.uuid}
                        value={bloadtype.name}
                        data-value={bloadtype.uuid}
                      >
                        {" "}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="0">
                <label className="label"> مكان العلاج</label>
                <br></br>
                <input
                  list="citylist"
                  id="city"
                  name="city"
                  className="input"
                  // value={state.city ? state.city : ""}
                  required
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.city === false ? "  يرجي  التأكد من  حروف المدينة" : ""}
                </h6>
                <datalist id="citylist">
                  {city.map((city) => (
                    <option
                      key={city.uuid}
                      value={city.name}
                      data-value={city.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> تاريخ الدخول </label>
                <br></br>
                <input
                  className="input1"
                  type="date"
                  required
                  name="indate"
                  value={state.indate ? state.indate : ""}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="">
                <label className="label"> تاريخ الخروج </label>
                <br></br>
                <input
                  className="input1"
                  type="date"
                  required
                  name="outdate"
                  value={state.outdate ? state.outdate : ""}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <label className="label"> نوع الخروج </label>

                <br></br>
                <select
                  className="input"
                  value={state.status ? state.status : ""}
                  required
                  onChange={handleChange}
                  name="status"
                >
                  <option value=""></option>

                  <option value="خروج طبيعي">خروج طبيعي</option>
                  <option value="خروج علي المسؤلية">خروج علي المسؤلية</option>
                  <option value="هروب">هروب</option>
                  <option value="وفاة">وفاة</option>
                  <option value="رافض الدخول ">رافض الدخول</option>
                </select>
               
              </div>
              <div className="c1">
                <label className="label">الجنس</label>
                <br />
                <br />
                <div className="">
                  <input
                    type="radio"
                    id="html"
                    className="kk"
                    name="fav_language"
                    value="ذكر"
                    required
                    onClick={() => setisMale(true)}
                  />
                  <label>ذكر</label>
                  <input
                    type="radio"
                    id="css"
                    className="kk"
                    name="fav_language"
                    value="انثي"
                    required
                    onClick={() => setisMale(false)}
                  />
                  <label>انثي</label>
                </div>
              </div>
            </div>
          </>
        )}
        {page === 2 && (
          <div>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات الاضافيه</Typography>
            <div className="flex">
              <div className="0">
                <div className="Flex1">
                  <label className="label"> محل الأقامة</label>
                  <label className="label1">اختياري</label>
                </div>

                <br></br>
                <input
                  list="citylist"
                  id="address"
                  name="address"
                  value={state.address ? state.address : ""}
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.city === false ? "  يرجي  من  حروف المدينة" : ""}
                </h6>
                <datalist id="citylist">
                  {city.map((city) => (
                    <option
                      key={city.uuid}
                      value={city.name}
                      data-value={city.uuid}
                    >
                      {" "}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="0">
                <div className="Flex1">
                  <label className="label"> رقم الهاتف </label>
                  <label className="label1">اختياري</label>
                </div>

                <br></br>
                <input
                  list="phone_numberlist"
                  id="phone_number"
                  type="number"
                  value={state.phone_number ? state.phone_number : ""}
                  name="phone_number"
                  className="input"
                  style={{
                    margin: "1%",

                    textAlign: "end",
                    paddingRight: "2%",
                    height: "35px",
                  }}
                  onChange={handleChange}
                />
                <h6 className="R">
                  {Check?.phone_number === false ? "  يرجي  من  حروف رقم" : ""}
                </h6>
              </div>
            </div>
            <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <br />
          </div>
        )}
        {page === 3 && (
          <>
            <h1 className="C">{page}</h1>
            <br />

            <Typography className="R">بيانات التشخيص</Typography>

            <FormInput
              handleChangeTable1={handleChangeTable1}
              formInputData={formInputData}
              handleSubmit={handleSubmit}
              oncology={oncology}
              Check={Check}
              isloading={isloading}
            />

            <Box
              sx={{
                height: tableData.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table tableData={tableData} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        {page === 4 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R">بيانات العلاج</Typography>

            <FormInput2
              handleChangeTable2={handleChangeTable2}
              handleSubmit2={handleSubmit2}
              medicine={medicine}
              Check={Check}
              isloading2={isloading2}
            />
            <br />

            <Box
              sx={{
                height: tableData2.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table2 tableData2={tableData2} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        {page === 5 && (
          <>
            <h1 className="C">{page}</h1>
            <br />
            <Typography className="R"> الامراض المصاحبة</Typography>

            <FormInput3
              handleChangeTable3={handleChangeTable3}
              handleSubmit3={handleSubmit3}
              disease={disease}
              Check={Check}
              isloading3={isloading3}
            />
            <br />

            <Box
              sx={{
                height: tableData3.length <= 5 ? 500 : 1000,
                backgroundColor: "primary",
              }}
            >
              <Table3 tableData3={tableData3} handleDelete={handleDelete} />
            </Box>
          </>
        )}
        <div className="flex">
          {page >= 1 &&
          Check.bloadtypeName === true &&
          Check.city === true &&
          Check.name === true &&
          Check.birthday === true &&
          isMale != null ? (
            <button
              className="button"
              onClick={() =>
                page < 5 ?  SetPage(page+1) : onSubmit()
              }
            >
              {page === 5 ? "الحفظ" : "التالي"}
            </button>
          ) : null}

          {page != 1 ? (
            <button
              hidden={true}
              className="button R"
              onClick={() => SetPage(page > 1 ? page - 1 : page)}
            >
              الرجوع
            </button>
          ) : null}
        </div>
      </div>
    </div>
    </>
  
  );
};

export default New;

export const Table = ({ tableData, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                رمز الورم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                اسم الورم
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                المستوي{" "}
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                التاريخ التشخيص
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.code}</td>
                <td>{data.oncology}</td>
                <td>{data.level}</td>
                <td>{data.date}</td>
                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table2 = ({ tableData2, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
              اسم العلاج
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الكمية
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData2.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.medicine}</td>
                <td>{data.quantity}</td>

                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export const Table3 = ({ tableData3, handleDelete }) => {
  return (
    <div>
      <table className="table" id="customers">
        <thead>
          <tr>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                الرقم
              </h3>
            </th>
            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
              اسم المرض
              </h3>{" "}
            </th>

            <th>
              <h3
                style={{
                  direction: "rtl",
                  color: "white",
                  float: "right",
                }}
              >
                حذف{" "}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData3.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.diseases}</td>

                <td>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      width: "100px",
                    }}
                    onClick={() => handleDelete(data.uuid)}
                    className=""
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function FormInput({
  handleChangeTable1,
  oncology,
  Check,
  handleSubmit,
  isloading,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label">اسم الورم</label>
            <br></br>
            <input
              list="oncologylist"
              id="oncology"
              name="oncology"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable1}
            />
            <h6 className="R">
              {Check?.oncology === false ? "  يرجي التأكد  من  حروف الورم" : ""}
            </h6>
            <datalist id="oncologylist">
              {oncology.map((oncology) => (
                <option
                  key={oncology.uuid}
                  value={oncology.name}
                  data-value={oncology.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label">خطورة الورم </label>
            <br></br>
            <select
              className="input"
              name="level"
              id="level"
              onChange={handleChangeTable1}
            >
              <option></option>
              <option value="standard">standard risk</option>
              <option value="intermediate">intermediate risk</option>
              <option value="high">high risk</option>
            </select>
          </div>
        </div>

        <div className="flex">
          <div className="0">
            <label className="label">تاريخ التشخيص </label>
            <br></br>
            <input
              className="input"
              type="date"
              name="date"
              id="date"
              onChange={handleChangeTable1}
            ></input>
          </div>
          <div className="0">
            <br></br>
            <Button
              variant="contained"
              className="Save-Button"
              disabled={isloading}
              onClick={handleSubmit}
              style={{
                fontFamily: "Tajawal",
                textAlign: "center",
                minwidth: "100%",
              }}
            >
              أضافه{" "}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
}
function FormInput2({
  handleChangeTable2,
  medicine,
  Check,
  handleSubmit2,
  isloading2,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> الادوية </label>
            <br></br>
            <input
              list="medicinelist"
              id="medicine"
              name="medicine"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable2}
            />
            <h6 className="R">
              {Check?.medicine === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="medicinelist">
              {medicine.map((medicine) => (
                <option
                  key={medicine.uuid}
                  value={medicine.name}
                  data-value={medicine.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
          <div className="0">
            <label className="label"> عدد الجرعات</label>

            <br></br>
            <input
              className="input"
              type="number"
              required
              onChange={handleChangeTable2}
              name="quantity"
              id="quantity"
            ></input>
            <h6 className="R">
              {Check?.quantity === false ? "يرجي التأكد من عدد الارقام " : ""}
            </h6>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading2}
          onClick={handleSubmit2}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
          أضافه{" "}
        </Button>
      </div>
    </Box>
  );
}
function FormInput3({
  handleChangeTable3,
  disease,
  Check,
  handleSubmit3,
  isloading3,
}) {
  return (
    <Box
      sx={{
        height: 300,
        backgroundColor: "primary",
      }}
    >
      <div>
        <div className="flex">
          <div className="0">
            <label className="label"> اسم المرض </label>
            <br></br>
            <input
              list="diseaselist"
              id="diseases"
              name="diseases"
              className="input"
              style={{
                margin: "1%",

                textAlign: "end",
                paddingRight: "2%",
                height: "35px",
              }}
              onChange={handleChangeTable3}
            />
            <h6 className="R">
              {Check?.diseases === false ? "  يرجي  التأكد من  حروف " : ""}
            </h6>
            <datalist id="diseaselist">
              {disease.map((medicine) => (
                <option
                  key={medicine.uuid}
                  value={medicine.name}
                  data-value={medicine.uuid}
                >
                  {" "}
                </option>
              ))}
            </datalist>
          </div>
        </div>
        <br></br>
        <Button
          variant="contained"
          className="Save-Button"
          disabled={isloading3}
          onClick={handleSubmit3}
          style={{
            fontFamily: "Tajawal",
            textAlign: "center",
            minwidth: "100%",
          }}
        >
          أضافه{" "}
        </Button>
      </div>
    </Box>
  );
}
