import { useRef, useState, useEffect } from 'react';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';
import logo from '../logo192.jpg';
import axios from '../api/axios';
import { useAuth } from '../hooks/useAuth';


import '../index.css'
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
const LOGIN_URL = '/login';
const Login = () => {
    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [username, resetUser, userAttribs] = useInput('username', '')
    const [password, setpassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    Credentials: 'incloud'
                }
            );
           
            const accessToken = response?.data?.tokens?.access_token;
            const user =response?.data?.tokens?.username;
console.log(accessToken)
           
            const role = response?.data?.role;
         auth.login(user,accessToken,role)
        //     // resetUser();
        //     // setpassword('');
         localStorage.setItem('refresh',response?.data?.tokens?.refresh_token)
         cookies.set('accessToken',accessToken  );
   navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
<div className='App'>
<div className='C1111'>
    <div className='center'>
    <img src={logo} alt="Logo" width={'150vh'} />
    </div>

<section>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1>Login </h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    {...userAttribs}
                    required
                    className='input0'
                />

                <label htmlFor="password">Password:</label>
                <input
                className='input0'
                    type="password"
                    id="password"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                    required
                />
                <br/>
                <button>Login In</button>
                <div className="persistCheck">
                    <input
                        type="checkbox"
                        id="persist"
                        onChange={toggleCheck}
                        checked={check}
                    />
                    <label htmlFor="persist">Trust This Device</label>
                </div>
            </form>
          
             
   
  
        </section>
        <a href='https://www.teccube.ly/'>
                <h5> made by TecCube  </h5> 
                </a>
</div>


      
        </div>
    )
}

export default Login
