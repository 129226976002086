import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const Index = () => {
  return (
    <div className='center bg'>
<div>
<br ></br>
<h1  className='center ' style={{color:'white'}}>
أختيار مركز العلاج    
</h1>
<br ></br>

<select
                  className="input2"
            
                  required
                //   onChange={handleChange}
                  name="status"
                >
                  <option value=""></option>

                  <option value="الأطفال">الأطفال بنغازي </option>
                  {/* <option value="خروج علي المسؤلية">خروج علي المسؤلية</option>
                  <option value="هروب">هروب</option>
                  <option value="وفاة">وفاة</option>
                  <option value="رافض الدخول ">رافض الدخول</option> */}
                </select>
                <br></br>
                <br></br>


                <Link to='Home'  sx={{ color: '#fff' }}>
                <button
              className="button"
             
            >
              
     الدخول
      
            </button>
            </Link>
            
</div>

  

       
    </div>
  )
}
