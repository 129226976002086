import Cookies from "universal-cookie";
import axios from "../api/axios";


const cookies = new Cookies();

const tokens = cookies.get("accessToken");

// s

const Getbloadtypes = async () => {
  const response=  await axios
  .get("/report/oncology/bloodtype", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const GetGroups = async () => {
  const response=  await axios
  .get("/report/medicine/group", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getdiseases = async () => {
  const response=  await axios
  .get("/diseases", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getmedicinecity = async () => {
  const response=  await axios
  .get("/report/oncology/address", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getmedicine = async () => {
  const response=  await axios
  .get("/report/medicine", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetSex = async () => {
  const response=  await axios
  .get("/report/oncology/sex", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getbirthdate = async () => {
  const response=  await axios
  .get("/report/oncology/birthdate", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getindate = async () => {
  const response=  await axios
  .get("/report/oncology/indate", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const GetOutedate = async () => {
  const response=  await axios
  .get("/report/oncology/out", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getbloodtype = async () => {
  const response=  await axios
  .get("/report/patient/bloodtype", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const ReportService = {
  Getmedicine,
  Getmedicinecity,
  GetGroups,
  GetSex,
  Getbirthdate,
  Getbloadtypes,
  Getdiseases,
  Getindate,
  GetOutedate,
  Getbloodtype
}

export default ReportService;
