import Login from './components/Login';



import RequireAuth from './components/RequireAuth';

import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import './index.css'
import { Report } from './components/Report/Report';
import { View } from './components/view/view';
import { Index } from './components/view/Index';
import New from './components/view/New';
import Home from './components/view/Home';
import { One } from './components/Report/One';
const ROLES = {
  'User': 'superadmin',
  'Editor': 'superadmin',
  'Admin': 'superadmin'
}

function App() {

  return (
    <AuthProvider>

    <Routes>
    
        {/* public routes */}
        <Route path="login" element={<Login />} />
       
        {/* we want to protect these routes */}
      
          <Route element={<RequireAuth />}>
          <Route path="/" element={<Index />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home/View" element={<View />} />
            <Route path="/Home/New" element={<New />} />
            <Route path="/Home/Report" element={<Report />} />
            <Route path="/Home/Report" element={<Report />} />
            <Route path="/Home/Report/one/:type" element={<One />} />
          </Route>

       
   

        {/* catch all */}
     
    </Routes>
    </AuthProvider>
  );
}

export default App;