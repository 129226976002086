import React, { useEffect, useState } from 'react'

import DrawerAppBar from '../AppBar'

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DataService from '../../services/data.service';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const columns = [
 
  {
    field: 'name',
    headerName: 'name',
flex:1
  },
  {
    field: 'mothername',
    headerName: 'Mother name',
    flex:1
 
  },
  {
    field: 'passport',
    headerName: 'passport',
    flex:1
    
  },
  {
    field: 'NID',
    headerName: 'NID',
    flex:1
    
  },
  {
    field: 'phone_number',
    headerName: 'phone number',
    flex:1
  
  },
  {
    field: 'code',
    headerName: 'code',
    flex:1,
    renderCell: (params) => {
       
      // nsole.log(params.row.genres[0].name).arco
    
            return params.row.code;
          },
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];
const navItems = [];

   
export const View = () => {
    const [rows1, setrows] = useState({});
    const navigate = useNavigate();
    
  function Msg(params) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: params+ "!",
    });
    return 0;
  }
    try {
        useEffect(() => {
          DataService.GetPatients()
            .then((data) => data)
            .then((data) => setrows(data)).catch(function (err) {
              if (!err?.response) {
                Msg('No Server Response');
            } else if (err.response?.status === 400) {
               Msg('error');
            } else if (err.response?.status === 401 ||err.response?.status === 422) {
              console.clear();
              navigate('/');
              window.location.reload();
            }
            });
   
        }, []);
       
      } catch (err) {
       
      }
      console.log(rows1)
  return (
    <div>
 <DrawerAppBar navItems={navItems}/>

<Box sx={{ height: '80vh', width: '80%',margin:'100px' }}>
      <DataGrid
        rows={rows1?rows1:rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(rows1) => rows1?.uuid}
        disableSelectionOnClick
      
      />
    </Box>

    </div>
  )
}
