import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";




  const RequireAuth = ({ children }) => {
    const  auth  = useAuth();
    const location = useLocation();
console.log(auth)
    if(!auth.user){
      
        return ( 
        <Navigate to="/login"  state={{ from: location }} replace  />)
    }
   
    // console.log( auth?.roles)
   
    return (  <Outlet />
        //   auth?.roles?.find(r => r === allowedRoles)
         //    <Outlet />
        //     : auth?.accessToken //changed from user to accessToken to persist login after refresh
        //       ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        //     : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;