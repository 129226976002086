import { createContext, useContext, useState } from "react";
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
 


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState( cookies.get('user' ) ?  cookies.get('user'  ) : null);
    const [accessToken, setaccessToken] = useState(null);
    const [role, setrole] = useState(null);
const login = (user,accessToken,role) =>{
  
    setUser(user)
    console.log(accessToken);
   
    setaccessToken(accessToken)
    cookies.set('user',user);
    
    setrole(role)
}
const logout =(user) =>{
    setUser(null)
}
    return (
        <AuthContext.Provider value={{ user ,role,accessToken,login,logout }}>
            {children}
        </AuthContext.Provider>
    )
}
export const useAuth =() =>{
    return useContext(AuthContext)
}
