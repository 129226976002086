import React from 'react'
import DrawerAppBar from '../AppBar'

const Home = () => {
    const navItems = ['New','View','Report'];
  return (
    <div>
        <DrawerAppBar navItems = {navItems} />

    </div>
  )}

export default Home