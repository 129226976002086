import { Button } from "@mui/material";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { PureComponent } from 'react';
import { Link, useParams } from "react-router-dom";
import DrawerAppBar from "../AppBar";
import Birthdate from "./Birthdate";
import BloodType from "./BloodType";
import City from "./City";
import Group from "./Group";
import Indate from "./Indate";
import Outedate from "./outdate";
import Sex from "./Sex";


export const One = () => {
    let { type } = useParams();
    console.log(type)
  return (
    <div>
        {type === 'BloodType' ?  <BloodType /> :null }
        {type === 'Birthdate' ?  <Birthdate /> :null }
        {type === 'Indate' ?  <Indate /> :null }
        {type === 'Sex' ?  <Sex /> :null }
      {type === 'Group' ?  <Group /> :null }
      {type === 'City' ?  <City /> :null } 
      {type === 'Outedate' ?  <Outedate /> :null } 
        </div>

  )
}
