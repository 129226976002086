import { Button } from "@mui/material";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { PureComponent, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import DrawerAppBar from "../AppBar";
import Birthdate from "./Birthdate";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import ReportService from "../../services/report.service";




const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj';
}
export const Report = () => {
  const [rows, setrows] = useState({});
  const [item, setitem] = useState('');
  const [loading, setloading] = useState(false);
  useEffect(  ()  => {

 
   console.log(rows)
    ReportService.Getbloodtype()
    .then((data) => data)
    .then((data) => setrows(data))
   
    .catch(function (err) {
      if (!err?.response) {
        
    } else if (err.response?.status === 400) {
    
    } else if (err.response?.status === 401 ||err.response?.status === 422) {
      console.clear();
     
      window.location.reload();
    }
    });
    // setloading(true)

  },[])
 console.log(rows)
const navItems = []
  return (
    <div classname=''>
      <DrawerAppBar navItems={navItems} />
      <br /> <br />      <br /> <br />
      <div classname='center '>

    
          <Link to='one/Sex' sx={{ color: '#fff' }} style={{margin:'10px'}}>
<Button variant="contained">الجنس</Button>
          </Link>
        
    
          <Link to='one/BloodType' sx={{ color: '#fff' }}style={{margin:'10px'}}>
<Button variant="contained">فصيله الدم</Button>
          </Link>
 
      
          <Link to='one/City' sx={{ color: '#fff' }}style={{margin:'10px'}}>
          <Button variant="contained">المدينة</Button>
          </Link>
  
      
          <Link to='one/Group' sx={{ color: '#fff' }}style={{margin:'10px'}}>

<Button variant="contained">أحتياج ادوية الأورام </Button>
          </Link>
     
       
          <Link to='one/Birthdate' sx={{ color: '#fff' }}style={{margin:'10px'}}>
<Button variant="contained">تاريخ الميلاد</Button>
          </Link>
     
        
          <Link to='one/Indate' sx={{ color: '#fff' }}style={{margin:'10px'}}>
<Button variant="contained">تاريخ الدخول</Button>
          </Link>
       
        
          <Link to='one/Outedate' sx={{ color: '#fff' }}style={{margin:'10px'}}>

<Button variant="contained">حالات الخروج   </Button>
          </Link>
       
     
      
       

      </div>
{/* <div className="" > 
<br></br>
  نسبة فصائل الدم
<ResponsiveContainer  width={300} height={300}>
        <PieChart width={300} height={300}>
          <Pie
            data={rows}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="quantity
            "
          >
            {rows[0] !=undefined && rows.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
  

</div> */}

    </div>
  );
};
