import Cookies from "universal-cookie";
import axios from "../api/axios";


const cookies = new Cookies();

const tokens = cookies.get("accessToken");

// s
const GetCity = async () => {

    const response=  await axios
  .get("/citys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  })
  return response.data;

  
};
const GetPatients = async () => {

  const response=  await axios
.get("/patients", {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
})
return response.data;


};
const Getbloadtypes = async () => {
  const response=  await axios
  .get("/bloadtypes", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getnationalitys = async () => {
  const response=  await axios
  .get("/nationalitys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};

const Getdiseases = async () => {
  const response=  await axios
  .get("/diseases", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getmedicines = async () => {
  const response=  await axios
  .get("/medicines", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const Getoncologys = async () => {
  const response=  await axios
  .get("/oncologys", {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
  });
  return response.data;
};
const DataService = {
  Getoncologys,
  Getmedicines,
  Getnationalitys,
  GetCity,
  Getbloadtypes,
  Getdiseases,
  GetPatients,
}

export default DataService;
